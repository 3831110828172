
@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.modal :first-child.user-modal{
  overflow: auto;
  max-height: 95vh;
  .modal-content{
    padding: 0;
  }
}
.user-modal{
  .carousel-header{
    padding-right: 40px;
    margin: 0;
  }
  .carousel-container > div{
    padding: 20px;
  }
  .profile{
    .item{
      input{ margin-top: 4px; }
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .modal :first-child.user-modal{
    max-width: 720px;
  }
}