.users-comp{
  .users-comp-header{
    display: flex;
    justify-content: space-between;
    margin: 0 0 12px;
    .button-element {
      max-width: 140px;
    }
  }
  table{
    .username-column{
      margin: 4px 0;
      display: flex;
      align-items: center;
      img,.svg-container {
        margin: 0 8px;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: rgba(220,220,220,1);
        box-shadow: 0px 3px 8px 0px rgba(0,0,0,.1);
      }
      .svg-container{
        display: flex;
        justify-content: center;
        align-items: center;
        svg{
          height: 24px;
          width: 24px;
          .real-path{
            fill: #555;
          }
        }
      }
      .text{
        text-align: left;
        flex: 1;
        .display-name{
          margin: 2px 0 0;
          font-weight: 700;
          font-size: 14px;
          color: rgba(0,0,0,.8);
        }
      }
    }
  }
}