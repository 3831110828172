
.center-div-outer {
  .center-div-middle{
    > :first-child.article-modal{
      max-width: 620px;
      padding: 0px;
    }
  }
}

.article-modal{
  select,textarea{
    margin: 0 0 12px;
  }
  .button-element{
    margin: 24px 0 0 0;
  }
}