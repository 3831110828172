.sign-up{
  .legal{
    margin: 12px 0 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    .checkbox-element{
      margin: 0 8px 0 0;
    }
  }
}