@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.carousel{
  position: relative;
  overflow: hidden;  
  width: 100%;
  transition:height 0.5s $cubic-bezier-default;

  .carousel-container{
    position: relative;
    display: flex;
    align-items:flex-start;
    align-content:flex-start;
    transition: left 0.5s $cubic-bezier-default;
    & > *{
      height: auto;
      float: left;
      width: 100%;
      opacity: 0;
      transition: opacity 0.5s linear;
      overflow-y: hidden;
      &.active{
        opacity: 1;
      }
    }
  }
}


