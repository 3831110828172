@import '../../css/styles/colors';
@import '../../css/styles/variables';

.footer{
  background-color: $footer-background-color;
  color: $footer-font-color;
  .footer-sub{
    position: relative;
    padding: 40px 20px 20px;
    margin: 0 auto;
    max-width: $footer-max-width;
    a{
      color: $footer-font-color;
    }
    .footer-logo{
      margin: 0px 0 20px;
      .logo-c{
        display: block;
      }
    }
    .footer-blocks{
      margin: 40px 0;
      display: flex;
      .block{
        flex: 1;
        display: flex;
        flex-flow: column;
        .header{
          font-size: 20px;
          margin: 16px 0;
          font-weight: 500;
        }
        .links{
          display: flex;
          flex-flow: column;
          > a{
            margin: 0 0 12px;
            font-size: 14px;
            color: $footer-font-secondary-color;
          }
        }
        &.community{
          
          .links {
            flex-flow: row;
            flex-wrap: wrap;
            a {
              margin: 4px;
              padding: 6px;
              height: 40px;
              width: 40px;
              transition: all 0.3s;
              border-radius: 3px;
              svg {
                height: 100%;
                width: 100%;
                .real-path {
                  fill:$footer-font-secondary-color;
                } 
                .bg-path{
                  fill:$footer-background-color;
                }
              }
              &:hover{
                background-color: #FFF;
                svg{
                  .real-path{
                    fill: $primary-color;
                  }
                  .bg-path{
                    fill: #fff;;
                  }
                }
              }
            }
          }
        }
      }
    }
    
    .footer-legal{
      text-align: center;
      margin: 40px 0 0 0;
      color: $footer-font-secondary-color;
      > div{
        margin: 0px 0;
        font-size: 14px;
        line-height: 24px;
      }
    }
    @media screen and (min-width: $desktop-width) {
      .language-button{
        left: 20px;
        bottom: 28px;
        position: absolute;
      }
    }
    .language-button{
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 3px;
      .icon{
        height: 36px;
        width: 36px;
        padding: 6px;
        svg {
          .real-path{
            fill:#FFF;
            transition: 0.3s;
          }
        }
      }
      .text{
        padding: 0 8px 0 0;
      }
      &:hover{
        background-color: #FFF;
        .icon{
          svg .real-path{
            fill: $primary-color;
          }
        }
        .text{
          color: $primary-color;
        }
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .footer{
    display: flex;
    .footer-sub{
      margin: 0;
      width: 100%;
      .footer-blocks{
        flex-flow: column;
      }
    }
  }
}