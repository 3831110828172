.totp-modal.modal-main:first-child{
  max-width: 540px;
}

.totp-modal{
  .totp-modal-div{
    counter-reset: css-counter 0;
    .block{
      position: relative;
      margin: 20px;

      padding: 0 0 12px;
      border: 1px solid rgba(200,200,200,1);
      border-width: 0 0 1px;
      &:last-of-type{border-width: 0px;margin-bottom:0px;}
      display: flex;
      flex-flow: column;
      counter-increment: css-counter 1;
      .qr-code{
        margin: 12px 0;
      }
      &::before {
        left: -20px;
        position: absolute;
        content: counter(css-counter)"."; 
      }
      > span{
        margin: 0 0 12px;
        &:last-of-type{margin: 0px;}
      }
    }
  }
}