h1{
  font-size: 32px;
  margin: 0 0 32px 0;
}
h2{
  font-size: 24px;
  margin: 0 0 24px 0;
}
h3{
  font-size: 20px;
  margin: 0 0 20px 0;
}
h4{
  font-size: 16px;
  margin: 0 0 16px 0;
}
h5{
  font-size: 14px;
  margin: 0 0 16px 0;
}
h6{
  font-size: 12px;
  margin: 0 0 12px 0;
}
h1,h2,h3,h4,h5,h6{
  a{text-decoration: underline;}
}
p{
  font-size: 16px;
  margin: 0 0 12px 0;
}
ul{
  list-style: none;
  margin: 0 0 12px 0;
}
li{
  margin: 0 0 12px 0;
}
a, span.anchor{
  cursor: pointer;
  color: $primary-color;
  text-decoration: none;
}
a:hover, span.anchor:hover{
  text-decoration: underline;
}
label{
  display: block;
  color: #333;
  margin: 0 0 4px 0;
}