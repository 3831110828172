.account-security{
  > .block{
    display: flex;
    .text{
      flex: 1;
      .display{
        font-weight: 500;
        font-size: 16px;
        display: block;
        margin: 0 0 8px;
      }
      .sub-display{
        font-size: 14px;
        display: block;
      }
    }
    .button{
      margin-left: 12px;
      width: auto;
    }
   
  }
}