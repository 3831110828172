@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.navi-logo{
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  line-height: 48px;
  margin: 0px 0 8px 0;
  overflow: hidden;
  position: relative;
  display: flex;
  background-color: rgba(0,0,0,.5);
  justify-content: center;
  > a{
    // padding: 0 0 0 12px;
    height: 100%;
    color: #FFF;
    display: flex;
    text-decoration: none;
    > svg{
      height: 100%;
      .real-path{
        fill: #FFF;
      }
    }
    > .text{
      padding: 0 12px;
      display: block;
      font-size: 24px;
      font-weight: 700;
      font-style: italic;
    }
  }
}
@media screen and (min-width: $desktop-width) {
  .navi-main.toggle-width-active{
    .navi-logo{
      > a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        .logo-c{
          line-height: 12px;
          font-size: 12px;
          display: flex;
          flex-flow: column;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop-width) {
  .navi-main.top-navi{
    .navi-logo{
      background-color: rgba(0,0,0,0);
      margin: 0;
      > a{
        padding: 0 12px;
      }
      &::after{
        top: 15%;
        right: 0px;
        position: absolute;
        content: "";
        background-color: #FFF;
        background:linear-gradient(to bottom, $primary-color, rgba(255,255,255,1), $primary-color, );
        height: 70%;
        width: 1px;
      }
    }
  }
}