@import '../../../css/styles/colors';
@import '../../../css/styles/variables';

.checkbox-element{
  position: relative;
  background-color: #FFF;
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 3px;
  border:1px solid rgba(200,200,200,1);
  cursor: pointer;
  &:hover,
  &.checked{
    border-color: $primary-color;
  }
  
  .check{
    top: -10%;
    left: 25%;
    position: absolute;
    height: 100%;
    width: 50%;
    transform: scale(0.7) rotate(45deg);
    &::after{
      position: absolute;
      right: 0px;
      bottom: 0px;
      content:"";
      height: 90%;
      width: 30%;
      background-color: #FFF;
    }
    &::before{
      position: absolute;
      bottom: 0px;
      content:"";
      height: 15%;
      width: 100%;
      background-color: #FFF;
    }
  }
  &.checked{
    background-color: $primary-color;
    .check{
      display: block;
    }
  }
}
.sandbox .checkbox-element {
  &:hover,
  &.checked{
    border-color: $sandbox-primary-color;
  }
  &.checked{
    background-color: $sandbox-primary-color;
    .check{
      display: block;
    }
  }
}