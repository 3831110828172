@import '../../css/styles/colors';
@import '../../css/styles/variables';

.navi{
  height: 100%;
  position: relative;
  flex: 0 0 $navi-width;
  &.toggle-width-active{
    flex: 0 0 60px;
  }
}
@media screen and (max-width: $mobile-width) {
  .navi{
    top: 0px;
    left: 0px;
    position: fixed;
    z-index: 150;
    flex: 0 0 $navi-width;
  }
}

@media screen and (min-width: $desktop-width) {
  .app.top-navi .navi{
    height: auto;
    position: relative;
    background-color: $primary-color;
    flex: 0;
  }
}