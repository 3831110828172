


.article-item{
  .article-top-div{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  .article-image-div{
    margin: 24px 0;
    display: flex;
    justify-content: center;
    img{
      border-radius: 4px;
      max-width: 256px;
      max-height: 256px;
    }
  }
  h1{
    margin-bottom: 20px;
  }
  h2{
    margin-bottom: 12px;
  }
  section{
    margin-bottom: 24px;
  }
  .article-title-div{
    margin: 0 0 20px;
  }
}